import { useState } from "react";
import { FiX, FiChevronLeft, FiChevronRight } from "react-icons/fi";

const images = [
  "/image/n.jpg",
  "/image/n1.jpg",
  "/image/n2.jpg",
  "/image/n3.jpg",
  "/image/n4.jpg",
  "/image/n5.jpg",
  "/image/n6.jpg",
  "/image/n7.jpg",
  "/image/u10.jpg",
];

const Gallery = () => {
  const [selectedImg, setSelectedImg] = useState<string | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);

  const openPopup = (index: number) => {
    setSelectedImg(images[index]);
    setCurrentIndex(index);
  };

  const closePopup = () => {
    setSelectedImg(null);
    setCurrentIndex(null);
  };

  const nextImage = () => {
    if (currentIndex !== null) {
      const newIndex = (currentIndex + 1) % images.length;
      setSelectedImg(images[newIndex]);
      setCurrentIndex(newIndex);
    }
  };

  const prevImage = () => {
    if (currentIndex !== null) {
      const newIndex = (currentIndex - 1 + images.length) % images.length;
      setSelectedImg(images[newIndex]);
      setCurrentIndex(newIndex);
    }
  };

  return (
   <div className="">
    <div className="text-center text-2xl font-bold py-4 bg-gray-100 shadow-md rounded-lg mb-4">
        OUR PROJECT PHOTO
    </div>
     <div className="flex justify-center p-1 md:p-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 md:gap-4">
        {images.map((img, index) => (
          <div
            key={index}
            className="cursor-pointer overflow-hidden rounded-lg shadow-lg"
            onClick={() => openPopup(index)}
          >
            <img src={img} alt="Gallery" className="w-full h-[70vh] transition-transform hover:scale-105" />
          </div>
        ))}
      </div>

      {selectedImg && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <button className="absolute top-4 right-4 text-white text-3xl" onClick={closePopup}>
            <FiX className="w-8 h-8" />
          </button>
          <button className="absolute left-4 text-white text-3xl" onClick={prevImage}>
            <FiChevronLeft className="w-8 h-8" />
          </button>
          <img src={selectedImg} alt="Selected" className="max-w-full max-h-[90vh] rounded-lg" />
          <button className="absolute right-4 text-white text-3xl" onClick={nextImage}>
            <FiChevronRight className="w-8 h-8" />
          </button>
        </div>
      )}
    </div>
   </div>
  );
};

export default Gallery;
