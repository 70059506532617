import React from 'react'
import Navbar from '../component/Navbar'
import AboutSection from '../component/About'
import Footer from '../component/Footer'
import { Helmet } from 'react-helmet-async'

function About() {
  return (
    <div>
       <Helmet>
              <title>EMCHRIS ENGINEERING NIG LTD - Construction & Engineering Experts</title>
              <meta name="description" content="EMCHRIS ENGINEERING NIG LTD specializes in high-quality engineering projects and construction services." />
              <meta name="keywords" content="Construction, Engineering, Building, EMCHRIS ENGINEERING, Luxury Projects" />
              <meta name="robots" content="index, follow" />
      
              {/* Open Graph for Social Media */}
              <meta property="og:title" content="EMCHRIS ENGINEERING NIG LTD - Top Engineering & Construction Services" />
              <meta property="og:description" content="Your trusted partner in high-quality engineering and construction projects." />
              <meta property="og:image" content="https://emchrisengineering.ng/image/logo1.png" />
              <meta property="og:url" content="https://emchrisengineering.ng" />
              <meta property="og:type" content="website" />
      
              {/* Twitter Meta Tags */}
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content="EMCHRIS ENGINEERING NIG LTD" />
              <meta name="twitter:description" content="Specialists in construction and engineering solutions." />
              <meta name="twitter:image" content="https://emchrisengineering.ng/image/logo1.png" />
            </Helmet>
      <Navbar/>
      <AboutSection/>
      <Footer/>
    </div>
  )
}

export default About