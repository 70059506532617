// src/components/LuxuryBagsPro.tsx
import React from "react";

const LuxuryBagsPro: React.FC = () => {
  return (
    <div className="px-[1rem] md:px-[10rem] my-[3rem]">
      <h1 className="text-center text-2xl font-bold mb-4">GET TO KNOW</h1>
      <h2 className="text-center text-3xl font-bold text-blue-600 mb-8">
      EMCHRIS ENGINEERING NIG LTD
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <img
          src="/image/u1.jpg"
          alt="Luxury Bag 1"
          className="w-full h-[50vh] object-cover"
        />
        <img
          src="/image/u12.jpg"
          alt="Luxury Bag 2"
          className="w-full h-[50vh] object-cover"
        />
        <img
          src="/image/u3.jpg"
          alt="Luxury Bag 3"
          className="w-full h-[50vh] object-cover"
        />
      </div>
      <p className="mt-8 text-gray-700 leading-8">
      Not only is EMCHRIS ENGINEERING NIG LTD a well-known name in the dynamic field of building and EMCHRIS ENGINEERING NIG LTD journey has been marked by a deep passion for innovative building solutions, an impressive portfolio of completed projects, and profound expertise that has made it a leader for visionary architects, property developers, and government bodies.

The nation has come to appreciate and value quality construction and sustainable infrastructure more because of our vision and dedication, which have turned ambitious blueprints into impressive realities. EMCHRIS ENGINEERING NIG LTD is the go-to expert in the construction industry because of its unmatched understanding of the intricacies and demands of the market.
      </p>
      <div className="mt-12">
        <img
          src="/image/n2.jpg"
          alt="Luxury Bag with Client"
          className="w-full h-[50vh] md:h-[85vh]"
        />
      <p className="mt-4 text-gray-700">
  Understanding the significance of unique requirements and tastes,
  EMCHRIS ENGINEERING NIG LTD provides highly tailored services by adjusting
  advice to each individual client.
</p>
<p className="mt-4 text-gray-700">
  Whether our clients are embarking on their first construction project, are interested in a specific budget, or are experienced developers wishing to enhance their portfolio, EMCHRIS ENGINEERING NIG LTD is fully dedicated to supporting them on their individual path.
</p>

      </div>
    </div>
  );
};

export default LuxuryBagsPro;
