import Navbar from "../component/Navbar";
import Banner from "../component/Banner";
import ProductCard from "../component/ProductView";
import Curbside from "../component/Curbside";
import Footer from "../component/Footer";
import { useEffect, useState } from "react";
import { getPosts } from "../Services/GetUser.service";
import { Product } from "../Model/prodduct";
import { Link, useNavigate } from "react-router-dom";
import CollaborativePartner from "../component/Collaborate";
import HeroSection from "../component/HowManYeaar";
import CommunityCommitment from "../component/NationalBuilding";
import Gallery from "../component/ImageGalary";
import { Helmet } from "react-helmet-async";

function Home() {
    const navigate = useNavigate()


  // enter path to image when ever you want to change the banner image
  const images = ["/image/u2.png"];
  const images2 = ["/image/u2.png"];
  const handleBuyNow = (item:Product) => {
  navigate(`/product/brands/checkout/${item.id}`)
  };
  return (
   <>
    <Helmet>
        <title>EMCHRIS ENGINEERING NIG LTD - Construction & Engineering Experts</title>
        <meta name="description" content="EMCHRIS ENGINEERING NIG LTD specializes in high-quality engineering projects and construction services." />
        <meta name="keywords" content="Construction, Engineering, Building, EMCHRIS ENGINEERING, Luxury Projects" />
        <meta name="robots" content="index, follow" />

        {/* Open Graph for Social Media */}
        <meta property="og:title" content="EMCHRIS ENGINEERING NIG LTD - Top Engineering & Construction Services" />
        <meta property="og:description" content="Your trusted partner in high-quality engineering and construction projects." />
        <meta property="og:image" content="https://emchrisengineering.ng/image/logo1.png" />
        <meta property="og:url" content="https://emchrisengineering.ng" />
        <meta property="og:type" content="website" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="EMCHRIS ENGINEERING NIG LTD" />
        <meta name="twitter:description" content="Specialists in construction and engineering solutions." />
        <meta name="twitter:image" content="https://emchrisengineering.ng/image/logo1.png" />
      </Helmet>
    <div>
      <Navbar />
      <Banner images={images} images2={images2} />
      <Gallery/>
      <CollaborativePartner/>
      <HeroSection/>
      <CommunityCommitment/>
      <Footer />
    </div>

   </>
  );
}

export default Home;
